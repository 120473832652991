/*
  Project class
 */

import Vue from 'vue';
import CRMEntity from '@/core/classes/entity/CRMEntity';
import {
  ENTITY_TYPES,
  ECOCERTIFIED_TYPES,
  USER_PROJECT_STATUSES,
  LIST_TYPES,
  TRIGGER_TYPES,
  COMMUNICATION_TYPES,
} from '@/config/enums';
import vars from '@/config/vars';
import store from '@/store';
import { Contact, Company } from "@/entities";
import { formatDateTimeNoWrap, isEmptyValue } from "@/shared/utils";
import { formatNumber, formatOrgNumber, roundNumber } from '@/shared/formatters';
import { getProjects, getLockedProjectReason } from "@/api/repositories/leadsRepository";
import {
  postProjectResponsible,
  deleteProjectResponsible,
  getProjects as getUserProjects,
  putProject,
  putProjectView,
  deleteProjects,
  putBulkProjects,
  postBulkProjectsResponsible,
} from "@/api/repositories/salesRepository";
import { fieldsBlackList } from "@/entities/project/fields";

const FieldProjectTitle = () => import("@/entities/project/views/FieldProjectTitle.vue");

const { DateTime } = require('luxon');

export class BaseProject extends CRMEntity {
  static hasResponsible = true;
  static entityKey = 'project';
  static userSectionAccessComponent = 'user_projects';
  static userSectionListTypeId = LIST_TYPES.USER_PROJECTS;
  static statusKey = 'user_project_status_id';
  static statusFilterKey = 'users.user_project_status_id';
  static idKey = 'project_id';
  static idsKey = 'project_ids';
  static fieldsBlackList = fieldsBlackList();
  static nameFieldKey = 'title';
  static datasetKey = 'projects';
  static statusNewId = USER_PROJECT_STATUSES.NEW;
  static routeName = 'Project';
  static smallIcon = 'project-sm';
  static deletedIcon = 'status-undesirable-bold';
  static triggerListTypeId = LIST_TYPES.PROJECT_TRIGGER;
  static triggerTypesForTags = [
    TRIGGER_TYPES.PROJECT,
  ];
  static hasContentStats = true;
  static entityTypeId = ENTITY_TYPES.PROJECT;

  // routes
  static allSectionRoutes = {
    view: 'Project',
    list: 'Projects',
  };
  static userSectionRoutes = {
    view: 'MyProject',
    list: 'MyProjects',
  };
  static titleComponent = FieldProjectTitle;

  // api methods
  static loadAllItemsFunc = getProjects;
  static loadUserItemsFunc = getUserProjects;
  static updateStatusFunc = putProject;
  static addResponsibleFunc = postProjectResponsible;
  static deleteResponsibleFunc = deleteProjectResponsible;
  static putViewedFunc = putProjectView;
  static deleteItemsFunc = deleteProjects;
  static putItemsFunc = putBulkProjects;
  static postItemsResponsible = postBulkProjectsResponsible;
  static loadLockedReason = getLockedProjectReason;

  static getEntityTypeText() {
    return Vue.prototype.$vDict(`projects.entity_type.text`);
  }

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict(`projects.entity_plural_type.text`);
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = BaseProject;
  }

  static getMapParams() {
    const entityType = this.getEntityPluralTypeText();

    return {
      categories: Vue.prototype.$lDict('client.project_categories'),
      defaultCategory: {
        name: Vue.prototype.$vDict('global.map_other_entities.text', {
          entityType,
        }),
        icon: 'p-other-ctg',
      },
      groupCategory: {
        name: Vue.prototype.$vDict('global.map_group_entities.text', {
          entityType,
        }),
        icon: 'p-group',
      },
    };
  }

  getMapData() {
    return {
      id: this.getValue('id'),
      title: this.getName(),
      location: this.getValue('location'),
      deleted: this.getValue('deleted'),
      category_id: this.getValue('category_id'),
    };
  }

  static getStatuses(_options = {}) {
    const options = {
      showSetStatus: false,
      showAllStatus: false,
      showAll: false,
      conditionalStatuses: null, // array
      ..._options,
    };
    const statusProps = USER_PROJECT_STATUSES.properties;
    const statuses = [];
    const statusesIds = [
      USER_PROJECT_STATUSES.NEW,
      USER_PROJECT_STATUSES.WORKING_WITH,
      USER_PROJECT_STATUSES.MONITORING,
      USER_PROJECT_STATUSES.IMPORTED,
      USER_PROJECT_STATUSES.UNDESIRABLE,
      USER_PROJECT_STATUSES.FINISHED,
    ];
    const conditionalStatuses = options.conditionalStatuses || store.getters.getEntityConditionalStatuses({
      entityTypeId: BaseProject.entityTypeId,
    });

    if (options.showSetStatus) {
      statuses.push({
        id: null,
        key: 'set',
        name: Vue.prototype.$vDict('global.text_set_status.text'),
        tooltip: Vue.prototype.$vDict('global.tooltip_project_status.text'),
        icon: 'custom-sales',
      });
    }

    if (options.showAllStatus) {
      statuses.push({
        id: 'all',
        key: 'all',
        name: Vue.prototype.$vDict('enums.user_project_status_all.text'),
        icon: 'baseline-inbox',
      });
    }

    statusesIds.forEach(statusId => {
      const statusProp = statusProps[statusId];
      const isActualStatus = options.showAll ||
        !statusProp.conditional ||
        conditionalStatuses.includes(statusId);

      if (isActualStatus) {
        statuses.push({
          ...statusProp,
          id: statusId,
          name: Vue.prototype.$vDict(`enums.user_project_status_${statusProp.key}.text`) || statusProp.name,
        });
      }
    });

    return CRMEntity.getEntityStatuses(statuses);
  }

  static getNotAvailableText() {
    return Vue.prototype.$vDict('projects.project_not_available.text');
  }

  getDeleteInformation() {
    const reasonId = this.getValue('reason_of_deletion');
    return {
      date: this.getValue('latest_update_at'),
      reason: {
        id: reasonId,
        name: Vue.prototype.$lFind('client.project_reason_of_deletion', { id: reasonId, prop: 'name' }),
      },
    };
  }

  getName() {
    return this.isUnknown() ? Vue.prototype.$vDict('project.project_name_unknown.text') : this.data.title;
  }

  isUnknown() {
    return !this.getValue('title');
  }

  getTypeName() {
    return Vue.prototype.$vDict('project.project_type_text.text');
  }

  getFieldValue(fieldKey, listTypeId = null, options = {}) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'roles':
        if (_.has(this.data, 'contact_roles')) {
          res = this.getCompanyContactRoles(this.data.contact_roles);
        } else if (_.has(this.data, 'company_roles')) {
          res = this.getCompanyContactRoles(this.data.company_roles);
        }
        break;
      case 'usage_area':
      case 'construction_area':
      case 'gross_area':
      case 'gross_volume':
      case 'renovation_area':
      case 'demolition_area':
      case 'decontamination_area':
        res = fieldValue ? formatNumber(fieldValue) : null;
        break;
      case 'latest_update_at':
      case 'updated_at':
      case 'modified_at':
      case 'delivered_at':
      case 'planning_approval_date':
        res = formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, utc: true });
        break;
      case 'value':
        if (this.data.value_unoff) {
          res = Vue.prototype.$vDict('global.text_value_unoff.text');
        } else {
          res = this.getFormattedBudgetValue(options.showCurrency) || '—';
        }
        break;
      case 'stage_id':
        res = this.getFieldStage();
        break;
      case 'start_date':
        res = this.data.start_unoff ? Vue.prototype.$vDict('global.text_start_date_unoff.text') : this.data.start_text;
        break;
      case 'finish_date':
        res = this.data.finish_text;
        break;
      case 'planning_stage_id':
        res = Vue.prototype.$lFind('client.planning_stages', { id: fieldValue, prop: 'name' });
        break;
      case 'ecocertified_type_id':
        value = this.getCertifications(listTypeId);
        res = value.length > 0 ? value.map(el => el.name) : null;
        break;
      case 'contract_type_id':
        res = Vue.prototype.$lFind('client.contract_types', { id: fieldValue, prop: 'name' });
        break;
      // case 'city':
      //   res = this.getValues(['city', 'postcode_text']).join(', ');
      //   break;
      case 'street':
        res = this.getValues(['street', 'street_number']).join(', ');
        break;
      case 'residence_forms':
        res = Vue.prototype.$lFind('client.residence_forms', { ids: fieldValue });
        break;
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'building_purpose':
        res = Vue.prototype.$lFind('client.building_purpose', { id: fieldValue, prop: 'name' });
        break;
      case 'materials':
        res = this.getMaterials();
        break;
      case 'url':
        res = this.getLinks();
        break;
      case 'uncertainty_id':
        res = Vue.prototype.$lFind('client.uncertainties', { id: fieldValue, prop: 'name' });
        break;
      case 'postcode':
        res = this.getValue('postcode_text');
        res = this.getPostcodeTextFromValue(res);
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'tags':
        res = this.getFieldTags(dataset);
        break;
      case 'region_id':
        res = this.getAdministrativeUnit(fieldValue, 'region_id', dataset);
        break;
      case 'district_id':
        res = this.getAdministrativeUnit(fieldValue, 'district_id', dataset);
        break;
      case 'responsible':
        res = this.getResponsibles(dataset.users);
        break;
      case 'companies_on_project':
        res = this.getFieldCompaniesOnProject();
        break;
      case 'client_statuses':
        value = this.getClientStatuses(dataset);
        res = value.length > 0 ? value : null;
        break;
      case 'contacts':
        res = this.getContacts(dataset);
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getFieldStage() {
    if (!this.hasValue('stage_id')) {
      return null;
    }

    const stageIndex = this.getCurrentStageIndex();
    const stageData = {
      index: stageIndex,
      stage: stageIndex !== -1 ? this.getCurrentStage(stageIndex) : {
        id: -1,
        name: Vue.prototype.$vDict('project.empty_stage_icon_tooltip.text'),
      },
    };

    return stageData.stage ? stageData : null;
  }

  getCurrentStageIndex() {
    const stages = Vue.prototype.$lDict('client.project_stages');
    const companies = this.getValue('companies', []);
    const isConstructionFinished = this.getValue('finish_date') && DateTime.fromISO(this.getValue('finish_date')) <= DateTime.now();
    const isConstructionStarted = this.getValue('start_date') && DateTime.fromISO(this.getValue('start_date')) <= DateTime.now();
    const lastStageIndex = stages.length - 1;
    let stageIndex;

    if (isConstructionFinished) {
      stageIndex = lastStageIndex;
    } else if (companies.length === 0) {
      stageIndex = -1;
    } else if (isConstructionStarted) {
      stageIndex = lastStageIndex;
    } else {
      stageIndex = _.findIndex(stages, { id: this.getValue('stage_id') });
    }

    return stageIndex;
  }

  getCurrentStage(stageIndex = null) {
    const stages = Vue.prototype.$lDict('client.project_stages');
    const resIndex = stageIndex !== null ? stageIndex : this.getCurrentStageIndex();
    return resIndex !== -1 ? stages[resIndex] : null;
  }

  getCertifications(listTypeId = null) {
    let res = [];

    if (this.data.ecocertified_type_id === ECOCERTIFIED_TYPES.YES) {
      const envTypes = Object.values(ECOCERTIFIED_TYPES);
      const certs = Vue.prototype.$lFind('client.certification_types', {
        ids: this.data.certification_types,
      }, listTypeId);

      res = certs.filter(el => !envTypes.includes(el.id));

      if (res.length === 0) {
        res = [Vue.prototype.$lFind('client.ecocertified_types', { id: ECOCERTIFIED_TYPES.YES })];
      }
    }

    return res;
  }

  getMainCategory() {
    const cats = Vue.prototype.$lDict('client.project_categories');
    let category = null;

    cats.forEach(cat => {
      if (!category) {
        const { icon } = cat;

        if (cat.id === this.data.category_id) {
          category = cat;
        } else {
          category = cat.items.find(el => el.id === this.data.category_id);
        }

        if (category) {
          category.icon = icon;
        }
      }
    });

    return category;
  }

  getAddress() {
    const formatedPostcode = this.getPostcodeTextFromValue(this.data.postcode_text);
    const addressStreet = _.compact([this.data.street, this.data.street_number]).join(' ');
    const addressCity = _.compact([formatedPostcode, this.data.city]).join(' ');

    return _.compact([addressStreet, addressCity]).join(', ');
  }

  getExpectedUpdateText() {
    let res = '';

    if (this.data.update_ended) {
      res = Vue.prototype.$vDict('project.updates_expected.text');
    } else if (this.data.expected_update) {
      const expectedDate = DateTime.fromISO(this.data.expected_update);
      const resDate = expectedDate < DateTime.now() ? DateTime.now() : expectedDate;
      const langIso = Vue.prototype.$getDictLanguage('intlIso');

      res = resDate.setLocale(langIso).toFormat(vars.LUXON_FORMAT_SHORT_MONTH_YEAR);
    }

    return res;
  }

  getSortedRoles() {
    const roles = this.getValue('roles', []);
    return roles.length > 0 ? this.sortRolesByPriority(roles) : [];
  }

  getFieldCompaniesOnProject() {
    // const field = fields.find(el => el.key === 'companies_on_project');
    // const filterRoleIds = _.get(field, 'company_roles') || [];
    const contactRoles = Vue.prototype.$lDict('client.contact_roles', { attr: 'mapItems' });
    const companies = this.getValue('role_by_company', []);

    // if (projectRoles.length > 0 && filterRoleIds.length > 0) {
    //   projectRoles = projectRoles.filter(el => filterRoleIds.includes(el.company_role_id));
    // }
    //
    // projectRoles = _.groupBy(projectRoles, 'company_id');

    return companies.map(company => {
      const roles = Company.prepareCompanyRoles(company?.roles || []);

      return {
        company_id: company.company_id,
        company_name: company.company_name,
        company_org_number: company.company_org_nr ? formatOrgNumber(company.company_org_nr, company.company_country_id) : null,
        roles: roles.map(role => ({
          ...role,
          contacts: role.contacts
            .filter(contact => !contact.expired)
            .map(contact => ({
              ...contact,
              contact_role_name: contactRoles[contact.contact_role_id]?.name,
              contact_phones: this.getCommunicationsInfoFrom(contact.communications, [
                COMMUNICATION_TYPES.PHONE,
                COMMUNICATION_TYPES.MOBILE,
              ]),
              contact_emails: this.getCommunicationsInfoFrom(contact.communications, [COMMUNICATION_TYPES.EMAIL]),
              mail_params: this.getMailParams(),
            })),
        })),
      };
    });
  }

  getContacts(dataset) {
    const res = [];

    _.uniq(this.data.contacts).forEach(contactId => {
      const contactData = this.getDatasetItem(dataset, 'contacts', contactId);

      if (contactData) {
        res.push(new Contact(contactData));
      }
    });

    return res;
  }

  getLinks() {
    const links = this.getValue('url', []);
    return this.getExternalLinks(links);
  }

  getXplorerId() {
    const entityExternalIds = this.getValue('external_ids', []);
    const enumExternalIds = store.getters.getLookupEnum('PROJECT_EXTERNAL_IDS') || {};
    const externalId = entityExternalIds.find(el => el.external_type_id === enumExternalIds.ISTAV_ID);

    return externalId ? externalId.value : null;
  }

  getMaterials() {
    const materialData = this.getValue('material_data', []);

    return materialData.map((material) => ({
      id: material.material_id,
      name: material.replacement_name ?
        material.replacement_name :
        Vue.prototype.$lFind('client.materials', { id: material.material_id, prop: 'name' }),
    }));
  }

  getMailParams() {
    const projectName = this.getName();
    return {
      subject: projectName || '',
    };
  }
}
