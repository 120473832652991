/*
  Tender class
 */

import Vue from 'vue';
import CRMEntity from '@/core/classes/entity/CRMEntity';
import {
  ENTITY_TYPES,
  USER_TENDER_STATUSES,
  LIST_TYPES,
  TRIGGER_TYPES,
  TENDER_SUBJECTS,
} from '@/config/enums';
import { Project, Company, Contact } from '@/entities';
import { formatDateTimeNoWrap, isEmptyValue } from "@/shared/utils";
import { getTenders } from "@/api/repositories/leadsRepository";
import {
  postTenderResponsible,
  deleteTenderResponsible,
  getTenders as getUserTenders,
  putTender,
  putTenderView,
  putBulkTenders,
  deleteTenders,
  postBulkTendersResponsible,
} from "@/api/repositories/salesRepository";
import { fieldsBlackList } from "@/entities/tender/fields";

const FieldTenderTitle = () => import("@/entities/tender/views/FieldTenderTitle.vue");

export class BaseTender extends CRMEntity {
  static hasResponsible = true;
  static entityKey = 'tender';
  static userSectionAccessComponent = 'user_tenders';
  static userSectionListTypeId = LIST_TYPES.USER_TENDERS;
  static statusKey = 'user_tender_status_id';
  static statusFilterKey = 'users.user_tender_status_id';
  static idKey = 'tender_id';
  static idsKey = 'tender_ids';
  static fieldsBlackList = fieldsBlackList();
  static nameFieldKey = 'name';
  static datasetKey = 'tenders';
  static statusNewId = USER_TENDER_STATUSES.NEW;
  static routeName = 'Tender';
  static smallIcon = 'tender-sm';
  static triggerListTypeId = LIST_TYPES.TENDER_TRIGGER;
  static triggerTypesForTags = [
    TRIGGER_TYPES.TENDER,
  ];
  static hasContentStats = true;
  static entityTypeId = ENTITY_TYPES.TENDER;

  // routes
  static allSectionRoutes = {
    view: 'Tender',
    list: 'Tenders',
  };
  static userSectionRoutes = {
    view: 'MyTender',
    list: 'MyTenders',
  };
  static titleComponent = FieldTenderTitle;

  // api methods
  static addResponsibleFunc = postTenderResponsible;
  static deleteResponsibleFunc = deleteTenderResponsible;
  static putViewedFunc = putTenderView;
  static loadAllItemsFunc = getTenders;
  static loadUserItemsFunc = getUserTenders;
  static updateStatusFunc = putTender;
  static putItemsFunc = putBulkTenders;
  static deleteItemsFunc = deleteTenders;
  static postItemsResponsible = postBulkTendersResponsible;

  static getEntityTypeText() {
    return Vue.prototype.$vDict(`tenders.entity_type.text`);
  }

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict(`tenders.entity_plural_type.text`);
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = BaseTender;
  }

  static getStatuses(_options = {}) {
    const options = {
      showSetStatus: false,
      showAllStatus: false,
      ..._options,
    };
    const statusProps = USER_TENDER_STATUSES.properties;
    const statuses = [];
    const statusesIds = [
      USER_TENDER_STATUSES.NEW,
      USER_TENDER_STATUSES.WORKING_WITH,
      USER_TENDER_STATUSES.MONITORING,
      USER_TENDER_STATUSES.UNDESIRABLE,
      USER_TENDER_STATUSES.FINISHED,
    ];

    if (options.showSetStatus) {
      statuses.push({
        id: null,
        name: Vue.prototype.$vDict('global.text_set_status.text'),
        tooltip: Vue.prototype.$vDict('global.tooltip_tender_status.text'),
        key: 'all',
        icon: 'tender',
      });
    }

    if (options.showAllStatus) {
      statuses.push({
        id: 'all',
        key: 'all',
        name: Vue.prototype.$vDict('enums.user_tender_status_all.text'),
        icon: 'baseline-inbox',
      });
    }

    statusesIds.forEach(statusId => {
      const statusProp = statusProps[statusId];

      statuses.push({
        ...statusProp,
        id: statusId,
        name: Vue.prototype.$vDict(`enums.user_tender_status_${statusProp.key}.text`) || statusProp.name,
      });
    });

    return CRMEntity.getEntityStatuses(statuses);
  }

  static getNotAvailableText() {
    return Vue.prototype.$vDict('tenders.tender_not_available.text');
  }

  getTypeName() {
    return Vue.prototype.$vDict('tender.tender_type_text.text');
  }

  getFieldValue(fieldKey, listTypeId = null, options = {}) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'company_role_id':
        value = this.getSubjectItems(this.data.tender_subject_id);
        res = value.length > 0 ? value : null;
        break;
      case 'company_role':
        value = this.getSubjectItems(TENDER_SUBJECTS.ROLE);
        res = value.length > 0 ? value : null;
        break;
      case 'materials':
        value = this.getSubjectItems(TENDER_SUBJECTS.MATERIALS);
        res = value.length > 0 ? value : null;
        break;
      case 'prequalification_date_range':
        res = this.data.prequalification_date_text;
        break;
      case 'first_day_materials_range':
        res = this.data.start_unoff ? Vue.prototype.$vDict('global.text_start_date_unoff.text') : this.data.first_day_materials_text;
        break;
      case 'last_day_materials_range':
        res = this.data.last_day_materials_text;
        break;
      case 'last_day_questions_range':
        res = this.data.last_day_questions_text;
        break;
      case 'last_day_application_range':
        res = this.data.last_day_application_text;
        break;
      // case 'city':
      //   res = this.getValues(['city', 'postcode_text']).join(', ');
      //   break;
      case 'street':
        res = this.getValues(['street', 'street_number']).join(', ');
        break;
      case 'latest_update_at':
      case 'delivered_at':
        res = formatDateTimeNoWrap(fieldValue, { toFormat: vars.LUXON_FORMAT_SHORT_DATE, utc: true });
        break;
      case 'value':
        if (this.data.value_unoff) {
          res = Vue.prototype.$vDict('global.text_value_unoff.text');
        } else {
          res = this.getFormattedBudgetValue(options.showCurrency) || '—';
        }
        break;
      case 'tender_type_id':
        res = Vue.prototype.$lFind('client.tender_types', { id: fieldValue, prop: 'name' });
        break;
      case 'tender_status_id':
        res = Vue.prototype.$lFind('client.tender_statuses', { id: fieldValue, prop: 'name' });
        break;
      case 'url':
        res = this.getLinks();
        break;
      case 'postcode':
        res = this.getValue('postcode_text');
        res = this.getPostcodeTextFromValue(res);
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getAsyncFieldValue(fieldKey, dataset) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'tags':
        res = this.getFieldTags(dataset);
        break;
      case 'region_id':
        res = this.getAdministrativeUnit(fieldValue, 'region_id', dataset);
        break;
      case 'district_id':
        res = this.getAdministrativeUnit(fieldValue, 'district_id', dataset);
        break;
      case 'responsible':
        res = this.getResponsibles(dataset.users);
        break;
      case 'name':
        res = {
          name: this.getName(),
          subtitle: this.getProjectSubtitle(dataset),
        };
        break;
      case 'company_id':
        res = this.getCompany(dataset);
        break;
      case 'contact_id':
      case 'contact_info':
      case 'print_contact_info':
        res = this.getContact(dataset);
        break;
      case 'email':
        value = this.getContact(dataset);
        res = value ? value.getFieldValue('email') : null;
        break;
      case 'phones':
        value = this.getContact(dataset);
        res = value ? value.getFieldValue('phones') : null;
        break;
      case 'project_id':
        res = this.getProject(dataset);
        break;
      case 'client_statuses':
        value = this.getClientStatuses(dataset);
        res = value.length > 0 ? value : null;
        break;
      default:
        res = undefined;
    }

    return res;
  }

  getCompany(dataset) {
    const company = this.getDatasetItem(dataset, 'companies', this.data.company_id);
    return company ? new Company(company) : null;
  }

  getContact(dataset) {
    const contact = this.getDatasetItem(dataset, 'contacts', this.data.contact_id);
    return contact ? new Contact(contact) : null;
  }

  getProject(dataset) {
    const project = this.getDatasetItem(dataset, 'projects', this.data.project_id);
    return project ? new Project(project) : null;
  }

  getProjectSubtitle(dataset = {}) {
    const project = this.getDatasetItem(dataset, 'projects', this.data.project_id);
    return project ? project.subtitle : '';
  }

  getSubjectItems(tenderSubjectId) {
    let res = [];

    if (tenderSubjectId === TENDER_SUBJECTS.ROLE) {
      res = Vue.prototype.$lFind('client.company_role_tender', { ids: [this.data.company_role_id] });
    } else if (tenderSubjectId === TENDER_SUBJECTS.MATERIALS) {
      res = Vue.prototype.$lFind('client.materials', { ids: this.data.materials });
    }

    return res;
  }

  getDescription() {
    const descPriority = [
      this.data.description,
      this.data.content_standard,
      this.data.content_ted,
    ];

    let resDesc = descPriority.find(desc => desc && desc.trim().length > 0);

    if (resDesc) {
      resDesc = resDesc.replace(/\n/g, '<br>');
    }

    return resDesc;
  }

  getDescriptions(project) {
    const res = {
      tenderDescription: this.getDescription(),
    };

    if (project) {
      const projectDescription = project.getValue('description');

      Object.assign(res, {
        projectWhatHappens: project.getValue('what_happens'),
        projectDescription: projectDescription ? projectDescription.replace(/\n/g, '<br>') : '',
      });
    }

    return res;
  }

  getCompanyRole() {
    return this.data.company_role_id ? Vue.prototype.$lFind('client.company_role_tender', { id: this.data.company_role_id }) : null;
  }

  getMaterials() {
    const materialData = this.data.material_data || [];
    const materialIds = materialData.map(el => el.material_id);

    return Vue.prototype.$lFind('client.materials', { ids: materialIds }) || [];
  }

  // getExternalIds() {
  //   const externalIds = this.getValue('external_ids');
  //   const res = [];
  //
  //   externalIds.forEach(externalId => {
  //     const { external_type_id, value } = externalId;
  //     const externalIdLookup = Vue.prototype.$lFind('client.tender_external_ids', {
  //       id: external_type_id,
  //     });
  //
  //     if (externalIdLookup) {
  //       res.push({
  //         id: externalIdLookup.id,
  //         name: externalIdLookup.name,
  //         value,
  //       });
  //     }
  //   });
  //
  //   return res;
  // }

  getTenderStatus() {
    return Vue.prototype.$lFind('client.tender_statuses', { id: this.data.tender_status_id });
  }

  getTenderType() {
    return Vue.prototype.$lFind('client.tender_types', { id: this.data.tender_type_id });
  }

  getLinks() {
    const links = this.getValue('links', []);
    return this.getExternalLinks(links);
  }

  getMailParams() {
    const tenderName = this.getFormattedValue('name', this.dataset);
    return {
      subject: tenderName ? tenderName.name : ''
    };
  }
}
