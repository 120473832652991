/*
  CompaniesList class
 */

import Vue from "vue";
import CRMEntityList from "@/core/classes/list/CRMEntityList";
import {
  USER_COMPANY_FOLDERS,
  LIST_TYPES,
} from "@/config/enums";
import { requiredFields, fieldsSections } from "@/entities/company/fields";
import { getCompaniesListsConfig } from "@/entities/company/config";
import store from "@/store";
import { getItemsId } from "@/shared/utils";
import { BaseCompany } from "@/entities/company/BaseCompany";

const CompaniesTableView = () => import('./views/CompaniesTableView');
const CompanyCardView = () => import('./views/CompanyCardView');
const CompaniesMap = () => import('./views/CompaniesMap');

const CompaniesTableViewWithContacts = () => import('./views/CompaniesTableViewWithContacts');
const CompanyCardViewWithContacts = () => import('./views/CompanyCardViewWithContacts');

const sortLookups = () => [
  { sortKey: 'company_type_id', lookupKey: 'client.company_types' },
];

export class BaseCompaniesList extends CRMEntityList {
  static urlStatusKey = 'folder';
  static statusIdWithNewItems = USER_COMPANY_FOLDERS.PROSPECTS;
  static entityClass = BaseCompany;
  static isStaticNavigation = true;

  constructor(listTypeId) {
    super(listTypeId, getCompaniesListsConfig);

    this.filterKeysWithScore = ['keywords'];
    this.mapFields = [
      'id',
      'name',
      'location',
      'company_type_id',
    ];

    // variables
    this.sortLookups = sortLookups();
    this.printFieldsBlacklist = [
      'status',
      'notes',
      'tags',
      // 'city',
      'post_city',
      // 'street',
      'visiting_street',
      'post_street',
      // 'street_number',
    ];

    //
    this.selfClass = BaseCompaniesList;
    this.initPropertiesByListType();
    this.getRequiredFields = requiredFields;
    this.getFieldsSections = fieldsSections;
  }

  initPropertiesByListType() {
    switch (this.listTypeId) {
      case LIST_TYPES.PROJECT_COMPANIES:
        this.tableView = CompaniesTableViewWithContacts;
        this.cardView = CompanyCardViewWithContacts;
        this.fixedUserFieldset = [
          { key: 'project_company_roles' },
          { key: 'addresses' },
          { key: 'phones' },
          { key: 'website' },
          { key: 'email' },
          { key: 'contacts' },
        ];
        break;
      default:
        this.mapView = CompaniesMap;
        this.tableView = CompaniesTableView;
        this.cardView = CompanyCardView;
    }
  }

  getEmptyListTitle() {
    return this.isFiltersFilled ?
      Vue.prototype.$vDict('entity_list.companies_list_empty_by_search.text') :
      Vue.prototype.$vDict('entity_list.companies_list_empty.text');
  }

  // getSubtitleForClearFolderForm() {
  //   return Vue.prototype.$vDict('entity_list.clear_folder_title.text');
  // }

  getContentStats() {
    return store.getters['Account/getUserContentStats']('user_company');
  }

  loadDatasetByQueryFields(items = [], queryFields = []) {
    if (queryFields.includes('addresses')) {
      const ids = items.reduce((acum, el) => ({
        regions: _.union(acum.regions, getItemsId(el.addresses, 'region_id')),
        districts: _.union(acum.districts, getItemsId(el.addresses, 'district_id')),
      }), {
        regions: [],
        districts: [],
      });

      this.loadGeographyDataset(ids);
    }
  }
}
